import type { ChartConfiguration, ChartType } from "chart.js";
import { TranslateService } from "@ngx-translate/core";
import { ChartData } from "./chart-data.model";
import { ChartDataGroup } from "./chart-data-group.model";

export class ChartSettingsBase<T extends ChartType> {
  public dataList: ChartData[] = [];
  public dataGroupList: ChartDataGroup[] = [];

  constructor(
    public readonly options: (
      translateService: TranslateService,
    ) => ChartConfiguration<T>,
    arg: ChartData[] | ChartDataGroup[],
  ) {
    if (arg.some((item) => item instanceof ChartData)) {
      this.dataList = arg as ChartData[];
    } else {
      this.dataGroupList = arg as ChartDataGroup[];
    }
  }
}

const DoughnutChartTypeRegistry: ChartType = "doughnut";
const BarChartTypeRegistry: ChartType = "bar";
const LineChartTypeRegistry: ChartType = "line";

export class DoughnutChartSettings extends ChartSettingsBase<
  typeof DoughnutChartTypeRegistry
> {}

export class BarChartSettings extends ChartSettingsBase<
  typeof BarChartTypeRegistry
> {}

export class LineChartSettings extends ChartSettingsBase<
  typeof LineChartTypeRegistry
> {}

export type ChartSettings =
  | DoughnutChartSettings
  | BarChartSettings
  | LineChartSettings;
