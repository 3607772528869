export enum GrayColor {
  C500 = "#737373",
}

export enum ChartColor {
  Amber = "#FACC15",
  Blue = "#3B82F6",
  Green1000 = "#31CA5E",
  Green700 = "#6EDA8E",
  Green400 = "#ADEABF",
  Green200 = "#D6F4DF",
  Indigo = "#6366F1",
  Orange = "#F59E0B",
  Pink = "#EC4899",
  Purple = "#A855F7",
  Red = "#FA3D15",
  Teal = "#14B8A6",
}

export const ContentMetricsColor = "#6596f0";
